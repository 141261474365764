<template>
    <div>
        <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
            <v-layout wrap justify-center>
              <v-flex text-left class="align-self-center">
                <span style="color: white">
                  {{ msg }}
                </span>
              </v-flex>
      
              <v-flex text-right>
                <v-btn small :ripple="false" text @click="showSnackBar = false">
                  <v-icon style="color: white">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-snackbar>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <span class="kumbhBold" style="font-size: 18px"
            >Legal Details (Docs shall be added only in PDF form.)</span
          >
        </v-flex>
        <v-flex xs12 pt-5>
          <v-layout wrap>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Contract Letter</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right>
              <v-layout wrap>
                <v-flex xs12>
                  <v-chip
                    v-if="contractLetter.name"
                    class="ma-2"
                    outlined
                    color="#005f32"
                    text-color="#929292"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left pa-2>
                        <span
                          >{{ contractLetter.name.slice(0, 40) }}
                          <span v-if="contractLetter.name.length > 40">
                            ...
                          </span></span
                        ></v-flex
                      >
                    </v-layout>
                  </v-chip>
                  <v-btn
                    color="#005f32"
                    dark
                    small
                    @click="$refs.contractLetter.click()"
                    ><span
                      style="
                        font-size: 12px;
                        text-align: end;
                        letter-spacing: 0px;
                        opacity: 1;
                      "
                    >
                      UPLOAD
                    </span></v-btn
                  >
                  <input
                    v-show="false"
                    id="file"
                    ref="contractLetter"
                    type="file"
                    accept="application/pdf"
                    @change="contractLetterUpload($event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Offer Letter </span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right>
              <v-layout wrap>
                <v-flex xs12>
                  <v-chip
                    v-if="offerLetter.name"
                    class="ma-2"
                    outlined
                    color="#005f32"
                    text-color="#929292"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left pa-2>
                        <span
                          >{{ offerLetter.name.slice(0, 40) }}
                          <span v-if="offerLetter.name.length > 40"> ... </span></span
                        ></v-flex
                      >
                    </v-layout>
                  </v-chip>
                  <v-btn color="#005f32" dark small @click="$refs.offerLetter.click()"
                    ><span
                      style="
                        font-size: 12px;
                        text-align: end;
                        letter-spacing: 0px;
                        opacity: 1;
                      "
                    >
                      UPLOAD
                    </span></v-btn
                  >
                  <input
                    v-show="false"
                    id="file"
                    ref="offerLetter"
                    type="file"
                    accept="application/pdf"
                    @change="offerLetterUpload($event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center py-5>
        <v-flex xs12 text-center>
          <v-btn color="#005f32" dark @click="add()"
            ><span>Save Details</span></v-btn
          >
        </v-flex>
      </v-layout>
    </div>
  </template>
    <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        showSnackBar: false,
        timeout: 5000,
        ServerError: false,
        msg: null,
        contractLetter: "",
        offerLetter: "",
        paasportOrlicence: "",
        formData: new FormData(),
      };
    },
  
    methods: {
        contractLetterUpload(event) {
        this.contractLetter = event.target.files[0];
      },
      offerLetterUpload(event) {
        this.offerLetter = event.target.files[0];
      },
    
      add() {
        let formData = new FormData();
        formData.append("id", localStorage.getItem("userid"));
        formData.append("contractLetter", this.contractLetter);
        formData.append("offerLetter", this.offerLetter);
      
        axios({
          method: "POST",
          url: "/employee/legaldocuments/upload",
          data: formData,
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              formData = new FormData();
              this.contractLetter = "";
              this.offerLetter = "";
              this.appLoading = false;
              this.$emit("stepper", {
                ref: "page1Tab",
                level: "tab-7",
              });
            } else {
              this.appLoading = false;
              this.msg = response.data.msg;
              this.showSnackBar = true;
              formData = new FormData();
            }
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>
    