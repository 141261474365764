<template>
    <div>
      <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
  
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <span class="kumbhBold" style="font-size: 18px"
            >Past Experience (Docs shall be added only in PDF form.)</span
          >
        </v-flex>
        <v-flex xs12 pt-5>
          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Past Experience Details</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right>
              <v-layout wrap>
                <v-flex xs12>
                  <v-chip
                    v-if="pastExperienceDetails.name"
                    class="ma-2"
                    outlined
                    color="#005f32"
                    text-color="#929292"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left pa-2>
                        <span
                          >{{ pastExperienceDetails.name.slice(0, 40) }}
                          <span v-if="pastExperienceDetails.name.length > 40">
                            ...
                          </span></span
                        ></v-flex
                      >
                    </v-layout>
                  </v-chip>
                  
                   
              
                 
      <v-btn color="#005f32" dark small @click="$refs.pastExperienceDetails.click()"
                    ><span
                      style="
                        font-size: 12px;
                        text-align: end;
                        letter-spacing: 0px;
                        opacity: 1;
                      "
                    >
                      UPLOAD
                    </span></v-btn
                  >
                  <input
                    v-show="false"
                    id="file"
                    ref="pastExperienceDetails"
                    type="file"
                    accept="application/pdf"
                    @change="pastExperienceDetailsUpload($event)"
                  /> 
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
         
         
          <v-layout wrap  pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Experience Certificate</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right>
              <v-layout wrap>
                <v-flex xs12>
                  <v-chip
                    v-if="experienceCertificate.name"
                    class="ma-2"
                    outlined
                    color="#005f32"
                    text-color="#929292"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left pa-2>
                        <span
                          >{{ experienceCertificate.name.slice(0, 40) }}
                          <span v-if="experienceCertificate.name.length > 40">
                            ...
                          </span></span
                        ></v-flex
                      >
                    </v-layout>
                  </v-chip>
                  <v-btn color="#005f32" dark small @click="$refs.pastexp.click()"
                    ><span
                      style="
                        font-size: 12px;
                        text-align: end;
                        letter-spacing: 0px;
                        opacity: 1;
                      "
                    >
                      UPLOAD
                    </span></v-btn
                  >
                  <input
                    v-show="false"
                    id="file"
                    ref="pastexp"
                    type="file"
                    accept="application/pdf"
                    @change="experienceCertificateUpload($event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Salary Slip</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right>
              <v-layout wrap>
                <v-flex xs12>
                  <v-chip
                    v-if="salarySlip.name"
                    class="ma-2"
                    outlined
                    color="#005f32"
                    text-color="#929292"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left pa-2>
                        <span
                          >{{ salarySlip.name.slice(0, 40) }}
                          <span v-if="salarySlip.name.length > 40">
                            ...
                          </span></span
                        ></v-flex
                      >
                    </v-layout>
                  </v-chip>
                  <v-btn color="#005f32" dark small @click="$refs.salarySlip.click()"
                    ><span
                      style="
                        font-size: 12px;
                        text-align: end;
                        letter-spacing: 0px;
                        opacity: 1;
                      "
                    >
                      UPLOAD
                    </span></v-btn
                  >
                  <input
                    v-show="false"
                    id="file"
                    ref="salarySlip"
                    type="file"
                    accept="application/pdf"
                    @change="salarySlipUpload($event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        
        
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center py-5>
        <v-flex xs12 text-center>
          <v-btn color="#005f32" dark @click="add()"
            ><span>Save Details</span></v-btn
          >
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
       
  
        appLoading: false,
       
        ServerError: false,
        showSnackBar : false,
        timeout: 5000,
        msg: null,
        experienceCertificate: "",
        salarySlip: "",
        pastExperienceDetails: "",
        formData: new FormData(),
      };
    },
  
    methods: {
        experienceCertificateUpload(event) {
        this.experienceCertificate = event.target.files[0];
      },
      pastExperienceDetailsUpload(event) {
        this.pastExperienceDetails = event.target.files[0];
      },
      salarySlipUpload(event) {
        this.salarySlip = event.target.files[0];
      },
     
      
      add() {
        let formData = new FormData();
        formData.append("id", localStorage.getItem("userid"));
        formData.append("experienceCertificate", this.experienceCertificate);
        formData.append("salarySlip", this.salarySlip);
        formData.append("pastExperienceDetails", this.pastExperienceDetails);
        axios({
          method: "POST",
          url: "/employee/pastexperience/upload",
          data: formData,
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              formData = new FormData();
              this.experienceCertificate = "";
              this.salarySlip = "";
              this.pastExperienceDetails = "";
              this.appLoading = false;
              this.$emit("stepper", {
                ref: "page1Tab",
                level: "tab-5",
              });
            } else {
              this.appLoading = false;
              this.msg = response.data.msg;
              this.showSnackBar = true;
              formData = new FormData();
            }
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>
  