<template>
    <div>
      <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
  
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>

      <v-layout wrap justify-center>
        <v-flex xs12>
          <span class="kumbhBold" style="font-size: 18px"
            >Insurance & Financial Details (Docs shall be added only in PDF form.)</span
          >
        </v-flex>
        <v-flex xs12 pt-5>
          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Nomination Details</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right>
              <v-layout wrap>
                <v-flex xs12>
                  <v-chip
                    v-if="nominationDetails.name"
                    class="ma-2"
                    outlined
                    color="#005f32"
                    text-color="#929292"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left pa-2>
                        <span
                          >{{ nominationDetails.name.slice(0, 40) }}
                          <span v-if="nominationDetails.name.length > 40">
                            ...
                          </span></span
                        ></v-flex
                      >
                    </v-layout>
                  </v-chip>
                  
                   
                 <v-btn color="#005f32" dark small @click="$refs.nominationDetails.click()"
                    ><span
                      style="
                        font-size: 12px;
                        text-align: end;
                        letter-spacing: 0px;
                        opacity: 1;
                      "
                    >
                      UPLOAD
                    </span></v-btn
                  >
                  <input
                    v-show="false"
                    id="file"
                    ref="nominationDetails"
                    type="file"
                    accept="application/pdf"
                    @change="nominationDetailsUpload($event)"
                  /> 
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Insurance Details</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right>
              <v-layout wrap>
                <v-flex xs12>
                  <v-chip
                    v-if="InsuranceDetails.name"
                    class="ma-2"
                    outlined
                    color="#005f32"
                    text-color="#929292"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left pa-2>
                        <span
                          >{{ InsuranceDetails.name.slice(0, 40) }}
                          <span v-if="InsuranceDetails.name.length > 40">
                            ...
                          </span></span
                        ></v-flex
                      >
                    </v-layout>
                  </v-chip>
                <v-btn color="#005f32" dark small @click="$refs.InsuranceDetails.click()"
                    ><span
                      style="
                        font-size: 12px;
                        text-align: end;
                        letter-spacing: 0px;
                        opacity: 1;
                      "
                    >
                      UPLOAD
                    </span></v-btn
                  >
                  <input
                    v-show="false"
                    id="file"
                    ref="InsuranceDetails"
                    type="file"
                    accept="application/pdf"
                    @change="InsuranceDetailsUpload($event)"
                  /> 
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Bank Details</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right>
              <v-layout wrap>
                <v-flex xs12>
                  <v-chip
                    v-if="bankDetails .name"
                    class="ma-2"
                    outlined
                    color="#005f32"
                    text-color="#929292"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left pa-2>
                        <span
                          >{{ bankDetails .name.slice(0, 40) }}
                          <span v-if="bankDetails .name.length > 40">
                            ...
                          </span></span
                        ></v-flex
                      >
                    </v-layout>
                  </v-chip>
                <v-btn color="#005f32" dark small @click="$refs.bankDetails.click()"
                    ><span
                      style="
                        font-size: 12px;
                        text-align: end;
                        letter-spacing: 0px;
                        opacity: 1;
                      "
                    >
                      UPLOAD
                    </span></v-btn
                  >
                  <input
                    v-show="false"
                    id="file"
                    ref="bankDetails"
                    type="file"
                    accept="application/pdf"
                    @change="bankDetailsUpload($event)"
                  /> 
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
         
         
          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">pf And Esic</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right>
              <v-layout wrap>
                <v-flex xs12>
                  <v-chip
                    v-if="pfAndEsic.name"
                    class="ma-2"
                    outlined
                    color="#005f32"
                    text-color="#929292"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left pa-2>
                        <span
                          >{{ pfAndEsic.name.slice(0, 40) }}
                          <span v-if="pfAndEsic.name.length > 40">
                            ...
                          </span></span
                        ></v-flex
                      >
                    </v-layout>
                  </v-chip>
                  <v-btn color="#005f32" dark small @click="$refs.pfAndEsic.click()"
                    ><span
                      style="
                        font-size: 12px;
                        text-align: end;
                        letter-spacing: 0px;
                        opacity: 1;
                      "
                    >
                      UPLOAD
                    </span></v-btn
                  >
                  <input
                    v-show="false"
                    id="file"
                    ref="pfAndEsic"
                    type="file"
                    accept="application/pdf"
                    @change="pfAndEsicUpload($event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center py-5>
        <v-flex xs12 text-center>
          <v-btn color="#005f32" dark @click="add()"
            ><span>Save Details</span></v-btn
          >
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        showSnackBar : false,
      timeout: 5000,
      msg: null,
  
        appLoading: false,
    
        ServerError: false,
      
        pfAndEsic: "",
        nominationDetails: "",
        InsuranceDetails: "",
        bankDetails : "",
        formData: new FormData(),
      };
    },
  
    methods: {
        pfAndEsicUpload(event) {
        this.pfAndEsic = event.target.files[0];
      },
      nominationDetailsUpload(event) {
        this.nominationDetails = event.target.files[0];
      },
      InsuranceDetailsUpload(event) {
        this.InsuranceDetails = event.target.files[0];
      },
      bankDetailsUpload(event) {
        this.bankDetails = event.target.files[0];
      },
   
     
      
      add() {
        let formData = new FormData();
        formData.append("id", localStorage.getItem("userid"));
        formData.append("pfAndEsic", this.pfAndEsic);
        formData.append("nominationDetails", this.nominationDetails);
        formData.append("InsuranceDetails", this.InsuranceDetails);
        formData.append("bankDetails", this.bankDetails );
        axios({
          method: "POST",
          url: "/employee/insurancedetails/upload",
          data: formData,
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              formData = new FormData();
              this.pfAndEsic = "";
              this.InsuranceDetails = "";
              this.nominationDetails = "";
              this.bankDetails = "";
              this.appLoading = false;
              this.$emit("stepper", {
                ref: "page1Tab",
                level: "tab-6",
              });
            } else {
              this.appLoading = false;
              this.msg = response.data.msg;
              this.showSnackBar = true;
              formData = new FormData();
            }
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>
  