<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <span class="kumbhBold" style="font-size: 18px"
          >Personal Details (Docs shall be added only in PDF form.)</span
        >
      </v-flex>
      <v-flex xs12 pt-5>
        <v-layout wrap pt-5>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Personal Email</span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-right>
            <v-layout wrap>
              <v-flex xs12>
                <v-chip
                  v-if="personalEmail.name"
                  class="ma-2"
                  outlined
                  color="#005f32"
                  text-color="#929292"
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left pa-2>
                      <span
                        >{{ personalEmail.name.slice(0, 40) }}
                        <span v-if="personalEmail.name.length > 40">
                          ...
                        </span></span
                      ></v-flex
                    >
                  </v-layout>
                </v-chip>
                
                  <!-- <v-text-field
                  v-model="personalEmail"
                  label="Email"
                  outlined
                  dense
                ></v-text-field>
             -->
               
               <v-btn color="#005f32" dark small @click="$refs.email.click()"
                  ><span
                    style="
                      font-size: 12px;
                      text-align: end;
                      letter-spacing: 0px;
                      opacity: 1;
                    "
                  >
                    UPLOAD
                  </span></v-btn
                >
                <input
                  v-show="false"
                  id="file"
                  ref="email"
                  type="file"
                  accept="application/pdf"
                  @change="personalEmailUpload($event)"
                /> 
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-5>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Gender</span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-right>
            <v-layout wrap>
              <v-flex xs12>
                <v-chip
                  v-if="gender.name"
                  class="ma-2"
                  outlined
                  color="#005f32"
                  text-color="#929292"
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left pa-2>
                      <span
                        >{{ gender.name.slice(0, 40) }}
                        <span v-if="gender.name.length > 40"> ... </span></span
                      ></v-flex
                    >
                  </v-layout> 
                </v-chip>
            <v-btn color="#005f32" dark small @click="$refs.gender.click()"
                  ><span
                    style="
                      font-size: 12px;
                      text-align: end;
                      letter-spacing: 0px;
                      opacity: 1;
                    "
                  >
                    UPLOAD
                  </span></v-btn
                >
                <input
                  v-show="false"
                  id="file"
                  ref="gender"
                  type="file"
                  accept="application/pdf"
                  @change="genderUpload($event)"
                /> 
                <!-- <v-radio-group
                dense
                hide-details
                v-model="gender"
                row
                
              >
                <v-radio label="Male" value="Male"></v-radio>
                <v-radio label="Female" value="Female"></v-radio>
                <v-radio label="Other" value="Other"></v-radio>
              </v-radio-group> -->
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-5>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Marital Status</span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-right>
            <v-layout wrap>
              <v-flex xs12>
                <v-chip
                  v-if="maritalStatus.name"
                  class="ma-2"
                  outlined
                  color="#005f32"
                  text-color="#929292"
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left pa-2>
                      <span
                        >{{ maritalStatus.name.slice(0, 40) }}
                        <span v-if="maritalStatus.name.length > 40">
                          ...
                        </span></span
                      ></v-flex
                    >
                  </v-layout>
                </v-chip>
  <v-btn color="#005f32" dark small @click="$refs.marry.click()"
                  ><span
                    style="
                      font-size: 12px;
                      text-align: end;
                      letter-spacing: 0px;
                      opacity: 1;
                    "
                  >
                    UPLOAD
                  </span></v-btn
                >
                <input
                  v-show="false"
                  id="file"
                  ref="marry"
                  type="file"
                  accept="application/pdf"
                  @change="maritalStatusUpload($event)"
                /> 
                <!-- <v-text-field
                v-model="maritalStatus "
                label="Marital Status"
                outlined
                dense
              ></v-text-field> -->
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-5>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Photograph</span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-right>
            <v-layout wrap>
              <v-flex xs12>
                <v-chip
                  v-if="photograph.name"
                  class="ma-2"
                  outlined
                  color="#005f32"
                  text-color="#929292"
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left pa-2>
                      <span
                        >{{ photograph.name.slice(0, 40) }}
                        <span v-if="photograph.name.length > 40">
                          ...
                        </span></span
                      ></v-flex
                    >
                  </v-layout>
                </v-chip>
                <v-btn color="#005f32" dark small @click="$refs.photo.click()"
                  ><span
                    style="
                      font-size: 12px;
                      text-align: end;
                      letter-spacing: 0px;
                      opacity: 1;
                    "
                  >
                    UPLOAD
                  </span></v-btn
                >
                <input
                  v-show="false"
                  id="file"
                  ref="photo"
                  type="file"
                  accept="application/pdf"
                  @change="photographUpload($event)"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-5>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Blood Group</span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-right>
            <v-layout wrap>
              <v-flex xs12>
                <v-chip
                  v-if="bloodGroup.name"
                  class="ma-2"
                  outlined
                  color="#005f32"
                  text-color="#929292"
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left pa-2>
                      <span
                        >{{ bloodGroup.name.slice(0, 40) }}
                        <span v-if="bloodGroup.name.length > 40">
                          ...
                        </span></span
                      ></v-flex
                    >
                  </v-layout>
                </v-chip>
                <v-btn color="#005f32" dark small @click="$refs.blood.click()"
                  ><span
                    style="
                      font-size: 12px;
                      text-align: end;
                      letter-spacing: 0px;
                      opacity: 1;
                    "
                  >
                    UPLOAD
                  </span></v-btn
                >
                <input
                  v-show="false"
                  id="file"
                  ref="blood"
                  type="file"
                  accept="application/pdf"
                  @change="bloodGroupUpload($event)"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-5>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Date of Birth</span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-right>
            <v-layout wrap>
              <v-flex xs12>
                <v-chip
                  v-if="dob.name"
                  class="ma-2"
                  outlined
                  color="#005f32"
                  text-color="#929292"
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left pa-2>
                      <span
                        >{{ dob.name.slice(0, 40) }}
                        <span v-if="dob.name.length > 40"> ... </span></span
                      ></v-flex
                    >
                  </v-layout>
                </v-chip>
                <v-btn color="#005f32" dark small @click="$refs.dob.click()"
                  ><span
                    style="
                      font-size: 12px;
                      text-align: end;
                      letter-spacing: 0px;
                      opacity: 1;
                    "
                  >
                    UPLOAD
                  </span></v-btn
                >
                <input
                  v-show="false"
                  id="file"
                  ref="dob"
                  type="file"
                  accept="application/pdf"
                  @change="dobUpload($event)"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-5>
      <v-flex xs12 text-center>
        <v-btn color="#005f32" dark @click="add()"
          ><span>Save Details</span></v-btn
        >
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
     
      showSnackBar : false,
        timeout: 5000,
        msg: null,
      appLoading: false,
      
      ServerError: false,

      photograph: "",
      bloodGroup: "",
      dob: "",
      personalEmail: "",
      gender: "",
      maritalStatus: "",
      formData: new FormData(),
    };
  },

  methods: {
    photographUpload(event) {
      this.photograph = event.target.files[0];
    },
    bloodGroupUpload(event) {
      this.bloodGroup = event.target.files[0];
    },
    dobUpload(event) {
      this.dob = event.target.files[0];
    },
    personalEmailUpload(event) {
      this.personalEmail = event.target.files[0];
    },
    genderUpload(event) {
      this.gender = event.target.files[0];
    },
    maritalStatusUpload(event) {
      this.maritalStatus = event.target.files[0];
    },
    add() {
      let formData = new FormData();
      formData.append("id", localStorage.getItem("userid"));
      formData.append("photograph", this.photograph);
      formData.append("bloodGroup", this.bloodGroup);
      formData.append("dob", this.dob);
      formData.append("personalEmail", this.personalEmail);
      formData.append("gender", this.gender);
      formData.append("maritalStatus", this.maritalStatus);
      axios({
        method: "POST",
        url: "/employee/personaldocument/upload",
        data: formData,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            formData = new FormData();
            this.photograph = "";
            this.bloodGroup = "";
            this.dob = "";
            this.personalEmail = "";
            this.gender = "";
            this.maritalStatus = "";
            this.appLoading = false;
            this.$emit("stepper", {
              ref: "page1Tab",
              level: "tab-2",
            });
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            formData = new FormData();
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
