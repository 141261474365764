<template>
    <div>
      <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
  
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <span class="kumbhBold" style="font-size: 18px"
            >Education Details (Docs shall be added only in PDF form.)</span
          >
        </v-flex>
        <v-flex xs12 pt-5>
          <v-layout wrap>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Tenth Certificate</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right>
              <v-layout wrap>
                <v-flex xs12>
                  <v-chip
                    v-if="tenthDMC.name"
                    class="ma-2"
                    outlined
                    color="#005f32"
                    text-color="#929292"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left pa-2>
                        <span
                          >{{ tenthDMC.name.slice(0, 40) }}
                          <span v-if="tenthDMC.name.length > 40">
                            ...
                          </span></span
                        ></v-flex
                      >
                    </v-layout>
                  </v-chip>
                  <v-btn
                    color="#005f32"
                    dark
                    small
                    @click="$refs.tenthDMC.click()"
                    ><span
                      style="
                        font-size: 12px;
                        text-align: end;
                        letter-spacing: 0px;
                        opacity: 1;
                      "
                    >
                      UPLOAD
                    </span></v-btn
                  >
                  <input
                    v-show="false"
                    id="file"
                    ref="tenthDMC"
                    type="file"
                    accept="application/pdf"
                    @change="tenthDMCUpload($event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Twelth Certificate</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right>
              <v-layout wrap>
                <v-flex xs12>
                  <v-chip
                    v-if="twelthDMC.name"
                    class="ma-2"
                    outlined
                    color="#005f32"
                    text-color="#929292"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left pa-2>
                        <span
                          >{{ twelthDMC.name.slice(0, 40) }}
                          <span v-if="twelthDMC.name.length > 40"> ... </span></span
                        ></v-flex
                      >
                    </v-layout>
                  </v-chip>
                  <v-btn color="#005f32" dark small @click="$refs.twelthDMC.click()"
                    ><span
                      style="
                        font-size: 12px;
                        text-align: end;
                        letter-spacing: 0px;
                        opacity: 1;
                      "
                    >
                      UPLOAD
                    </span></v-btn
                  >
                  <input
                    v-show="false"
                    id="file"
                    ref="twelthDMC"
                    type="file"
                    accept="application/pdf"
                    @change="twelthDMCUpload($event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Graduation Certificate</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right>
              <v-layout wrap>
                <v-flex xs12>
                  <v-chip
                    v-if="graduationDegree.name"
                    class="ma-2"
                    outlined
                    color="#005f32"
                    text-color="#929292"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left pa-2>
                        <span
                          >{{ graduationDegree.name.slice(0, 40) }}
                          <span v-if="graduationDegree.name.length > 40">
                            ...
                          </span></span
                        ></v-flex
                      >
                    </v-layout>
                  </v-chip>
                  <v-btn color="#005f32" dark small @click="$refs.gd.click()"
                    ><span
                      style="
                        font-size: 12px;
                        text-align: end;
                        letter-spacing: 0px;
                        opacity: 1;
                      "
                    >
                      UPLOAD
                    </span></v-btn
                  >
                  <input
                    v-show="false"
                    id="file"
                    ref="gd"
                    type="file"
                    accept="application/pdf"
                    @change="graduationDegreeUpload($event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>


          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Post-Graduation Certificate</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right>
              <v-layout wrap>
                <v-flex xs12>
                  <v-chip
                    v-if="postGraduationDegree.name"
                    class="ma-2"
                    outlined
                    color="#005f32"
                    text-color="#929292"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left pa-2>
                        <span
                          >{{ postGraduationDegree.name.slice(0, 40) }}
                          <span v-if="postGraduationDegree.name.length > 40">
                            ...
                          </span></span
                        ></v-flex
                      >
                    </v-layout>
                  </v-chip>
                  <v-btn color="#005f32" dark small @click="$refs.pg.click()"
                    ><span
                      style="
                        font-size: 12px;
                        text-align: end;
                        letter-spacing: 0px;
                        opacity: 1;
                      "
                    >
                      UPLOAD
                    </span></v-btn
                  >
                  <input
                    v-show="false"
                    id="file"
                    ref="pg"
                    type="file"
                    accept="application/pdf"
                    @change="postGraduationDegreeUpload($event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Diploma Certificate</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right>
              <v-layout wrap>
                <v-flex xs12>
                  <v-chip
                    v-if="diploma.name"
                    class="ma-2"
                    outlined
                    color="#005f32"
                    text-color="#929292"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left pa-2>
                        <span
                          >{{ diploma.name.slice(0, 40) }}
                          <span v-if="diploma.name.length > 40">
                            ...
                          </span></span
                        ></v-flex
                      >
                    </v-layout>
                  </v-chip>
                  <v-btn color="#005f32" dark small @click="$refs.diploma.click()"
                    ><span
                      style="
                        font-size: 12px;
                        text-align: end;
                        letter-spacing: 0px;
                        opacity: 1;
                      "
                    >
                      UPLOAD
                    </span></v-btn
                  >
                  <input
                    v-show="false"
                    id="file"
                    ref="diploma"
                    type="file"
                    accept="application/pdf"
                    @change="diplomaUpload($event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">CV</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right>
              <v-layout wrap>
                <v-flex xs12>
                  <v-chip
                    v-if="cv.name"
                    class="ma-2"
                    outlined
                    color="#005f32"
                    text-color="#929292"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left pa-2>
                        <span
                          >{{ cv.name.slice(0, 40) }}
                          <span v-if="cv.name.length > 40">
                            ...
                          </span></span
                        ></v-flex
                      >
                    </v-layout>
                  </v-chip>
                  <v-btn color="#005f32" dark small @click="$refs.cv.click()"
                    ><span
                      style="
                        font-size: 12px;
                        text-align: end;
                        letter-spacing: 0px;
                        opacity: 1;
                      "
                    >
                      UPLOAD
                    </span></v-btn
                  >
                  <input
                    v-show="false"
                    id="file"
                    ref="cv"
                    type="file"
                    accept="application/pdf"
                    @change="cvUpload($event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center py-5>
        <v-flex xs12 text-center>
          <v-btn color="#005f32" dark @click="add()"
            ><span>Save Details</span></v-btn
          >
        </v-flex>
      </v-layout>
    </div>
  </template>
    <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showSnackBar : false,
        timeout: 5000,
        msg: null,
        twelthDMC: "",
        tenthDMC: "",
        graduationDegree: "",
        postGraduationDegree: "",
        diploma: "",
        cv: "",
        formData: new FormData(),
      };
    },
  
    methods: {
        twelthDMCUpload(event) {
        this.twelthDMC = event.target.files[0];
      },
      tenthDMCUpload(event) {
        this.tenthDMC = event.target.files[0];
      },
      graduationDegreeUpload(event) {
        this.graduationDegree = event.target.files[0];
      },
      postGraduationDegreeUpload(event) {
        this.postGraduationDegree = event.target.files[0];
      },
      diplomaUpload(event) {
        this.diploma = event.target.files[0];
      },
      cvUpload(event) {
        this.cv = event.target.files[0];
      },
      add() {
        let formData = new FormData();
        formData.append("id", localStorage.getItem("userid"));
        formData.append("twelthDMC", this.twelthDMC);
        formData.append("tenthDMC", this.tenthDMC);
        formData.append("graduationDegree", this.graduationDegree);
        formData.append("postGraduationDegree", this.postGraduationDegree);
        formData.append("diploma", this.diploma);
        formData.append("CV", this.cv);
        axios({
          method: "POST",
          url: "/employee/educationdetails/upload",
          data: formData,
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              formData = new FormData();
              this.twelthDMC = "";
              this.tenthDMC = "";
              this.graduationDegree = "";
              this.postGraduationDegree = "";
              this.diploma = "";
              this.cv = "";
              this.appLoading = false;
              this.$emit("stepper", {
                ref: "page1Tab",
                level: "tab-4",
              });
            } else {
              this.appLoading = false;
              this.msg = response.data.msg;
              this.showSnackBar = true;
              formData = new FormData();
            }
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>
    