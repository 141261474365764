<template>
    <div>
        <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
            <v-layout wrap justify-center>
              <v-flex text-left class="align-self-center">
                <span style="color: white">
                  {{ msg }}
                </span>
              </v-flex>
      
              <v-flex text-right>
                <v-btn small :ripple="false" text @click="showSnackBar = false">
                  <v-icon style="color: white">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-snackbar>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <span class="kumbhBold" style="font-size: 18px"
            >Contact Details (Docs shall be added only in PDF form.)</span
          >
        </v-flex>
        <v-flex xs12 pt-5>
          <v-layout wrap>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Alternate Number</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right>
              <v-layout wrap>
                <v-flex xs12>
                  <v-chip
                    v-if="alternateNumber.name"
                    class="ma-2"
                    outlined
                    color="#005f32"
                    text-color="#929292"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left pa-2>
                        <span
                          >{{ alternateNumber.name.slice(0, 40) }}
                          <span v-if="alternateNumber.name.length > 40">
                            ...
                          </span></span
                        ></v-flex
                      >
                    </v-layout>
                  </v-chip>
                  <v-btn
                    color="#005f32"
                    dark
                    small
                    @click="$refs.alternateNumber.click()"
                    ><span
                      style="
                        font-size: 12px;
                        text-align: end;
                        letter-spacing: 0px;
                        opacity: 1;
                      "
                    >
                      UPLOAD
                    </span></v-btn
                  >
                  <input
                    v-show="false"
                    id="file"
                    ref="alternateNumber"
                    type="file"
                    accept="application/pdf"
                    @change="alternateNumberUpload($event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Emergency Contact Details</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right>
              <v-layout wrap>
                <v-flex xs12>
                  <v-chip
                    v-if="emergencyContactDetails.name"
                    class="ma-2"
                    outlined
                    color="#005f32"
                    text-color="#929292"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left pa-2>
                        <span
                          >{{ emergencyContactDetails.name.slice(0, 40) }}
                          <span v-if="emergencyContactDetails.name.length > 40"> ... </span></span
                        ></v-flex
                      >
                    </v-layout>
                  </v-chip>
                  <v-btn color="#005f32" dark small @click="$refs.emergencyContactDetails.click()"
                    ><span
                      style="
                        font-size: 12px;
                        text-align: end;
                        letter-spacing: 0px;
                        opacity: 1;
                      "
                    >
                      UPLOAD
                    </span></v-btn
                  >
                  <input
                    v-show="false"
                    id="file"
                    ref="emergencyContactDetails"
                    type="file"
                    accept="application/pdf"
                    @change="emergencyContactDetailsUpload($event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Permanent Address</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right>
              <v-layout wrap>
                <v-flex xs12>
                  <v-chip
                    v-if="permanentAddress.name"
                    class="ma-2"
                    outlined
                    color="#005f32"
                    text-color="#929292"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left pa-2>
                        <span
                          >{{ permanentAddress.name.slice(0, 40) }}
                          <span v-if="permanentAddress.name.length > 40">
                            ...
                          </span></span
                        ></v-flex
                      >
                    </v-layout>
                  </v-chip>
                  <v-btn color="#005f32" dark small @click="$refs.permanentAddress.click()"
                    ><span
                      style="
                        font-size: 12px;
                        text-align: end;
                        letter-spacing: 0px;
                        opacity: 1;
                      "
                    >
                      UPLOAD
                    </span></v-btn
                  >
                  <input
                    v-show="false"
                    id="file"
                    ref="permanentAddress"
                    type="file"
                    accept="application/pdf"
                    @change="permanentAddressUpload($event)"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center py-5>
        <v-flex xs12 text-center>
          <v-btn color="#005f32" dark @click="add()"
            ><span>Save Details</span></v-btn
          >
        </v-flex>
      </v-layout>
    </div>
  </template>
    <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        showSnackBar : false,
        timeout: 5000,
        msg: null,
        ServerError: false,
       
        alternateNumber: "",
        emergencyContactDetails: "",
        permanentAddress: "",
        formData: new FormData(),
      };
    },
  
    methods: {
        alternateNumberUpload(event) {
        this.alternateNumber = event.target.files[0];
      },
      emergencyContactDetailsUpload(event) {
        this.emergencyContactDetails = event.target.files[0];
      },
      permanentAddressUpload(event) {
        this.permanentAddress = event.target.files[0];
      },
      add() {
        let formData = new FormData();
        formData.append("id", localStorage.getItem("userid"));
        formData.append("alternateNumber", this.alternateNumber);
        formData.append("emergencyContactDetails", this.emergencyContactDetails);
        formData.append("permanentAddress", this.permanentAddress);
        axios({
          method: "POST",
          url: "/employee/contactdetails/upload",
          data: formData,
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              formData = new FormData();
              this.alternateNumber = "";
              this.emergencyContactDetails = "";
              this.permanentAddress = "";
              this.appLoading = false;
              this.$emit("stepper", {
                ref: "page1Tab",
                level: "tab-1",
              });
            } else {
              this.appLoading = false;
              this.msg = response.data.msg;
              this.showSnackBar = true;
              formData = new FormData();
            }
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>
    